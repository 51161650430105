<template>
  <div class="containerx">
    <el-dialog width="30%" title="修改设备类型" :visible.sync="show1">
      <el-form>
        <el-form-item label="设备名称" label-width="9rem">
          <el-input v-model="input1" placeholder="请输入" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="规格型号" label-width="9rem">
          <el-input v-model="specifications" placeholder="请输入" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="生产厂家" label-width="9rem">
          <el-input v-model="manufactor" placeholder="请输入" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="show1 = false">取 消</el-button>
        <el-button type="primary" @click="confirm1">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 新增设备类型 -->
    <el-dialog width="30%" title="新增设备类型" :visible.sync="show3">
      <el-form>
        <el-form-item label="设备名称" label-width="9rem">
          <el-input v-model="form3.name" placeholder="请输入" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="规格型号" label-width="9rem">
          <el-input v-model="form3.specifications" placeholder="请输入" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="生产厂家" label-width="9rem">
          <el-input v-model="form3.manufactor" placeholder="请输入" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="设备类别" label-width="9rem">
          <el-radio-group v-model="form3.type">
            <el-radio :label="0">环境设备</el-radio>
            <el-radio :label="1">能源设备</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-for="(item,index) in parameterList">
          <el-form-item label="参数" label-width="9rem">
            <el-input v-model="item.name" style="width: 40rem;margin-right: 1rem;" placeholder="请输入" autocomplete="off">
            </el-input>
            <span @click="del3(index)" style="color: red;cursor: pointer;">删除</span>
          </el-form-item>
        </div>

        <el-form-item label="" label-width="9rem">
          <el-button @click="add3" type="primary">添加参数</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show3 = false">取 消</el-button>
        <el-button @click="confirm3()" type="primary">确 定</el-button>
      </div>
    </el-dialog>
    <div class="btngrp" style="margin-bottom: 1rem;">
      <div @click="nyhj=index" v-for="(item,index) in hjny_arr" :class="nyhj==index?'btn1':'btn2'">{{item}}</div>
    </div>
    <div v-if="project==1">
      <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
        <div class="title">设备类型管理</div>
        <div style="display: flex;">
          <div @click="show3 = true" class="add">新增类型</div>
          <!-- <div @click="nyhj=1" v-if="nyhj==0" class="add">环境数据</div>
          <div @click="nyhj=0" v-if="nyhj==1" class="add">能源数据</div> -->
        </div>
      </div>
      <div class="table">
        <div class="tr bg">
          <div class="td1">
            <p>序号</p>
          </div>
          <div class="eqNameTd td">
            <p>设备名称</p>
          </div>
          <div class="specificationsTd td">
            <p>规格型号</p>
          </div>
          <div class="eqManufactorTd td">
            <p>生产厂家</p>
          </div>
          <div class="td3s td" style="justify-content: center;">
            <p style="margin-right: 2rem;">操作</p>
          </div>
        </div>
        <div @click="handleTb1(item)" class="tr" v-for="(item, index) in tableData" :class="index % 2 == 0?'bg1':'bg2'">
          <div class="td1">
            <p>{{ index+1 }}</p>
          </div>
          <div class="eqNameTd td">
            <p>{{item.name}}</p>
          </div>
          <div class="specificationsTd td">
            <p>{{item.specifications}}</p>
          </div>
          <div class="eqManufactorTd td">
            <p>{{item.manufactor}}</p>
          </div>
          <div class="td3s td" style="display: flex;padding-left: 0;">
            <div @click.stop="edit1(item)" class="tdd" style="color: rgb(43, 201, 222);">修改</div>
            <div @click.stop="delete1(item)" class="tdd" style="margin-right: 0;color: rgba(250, 105, 105, 1);">删除</div>
          </div>

        </div>
      </div>
      <div class="pagination">
        <el-pagination @size-change="sizeChange1" @current-change="currentChange1" style="color: white" background
          :page-sizes="[10, 15, 20]" :current-page="page1" :page-size="size1"
          layout="total, sizes, prev, pager, next, jumper" :total="total1">
        </el-pagination>
      </div>

    </div>
    <!-- 222222 -->
    <el-dialog width="30%" title="修改设备类型参数" :visible.sync="edit4_show">
      <el-input v-model="edit4_input"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="edit4_show = false">取 消</el-button>
        <el-button type="primary" @click="confirm4">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog width="30%" title="添加设备类型参数" :visible.sync="show2">
      <el-input v-model="input2"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show2 = false">取 消</el-button>
        <el-button type="primary" @click="confirm2">确 定</el-button>
      </div>
    </el-dialog>
    <div v-if="project==2">
      <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
        <div class="title">
          <span @click="project=1" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">设备类型管理&nbsp; / &nbsp;</span>
          <span style="color: rgba(43, 201, 222, 1);cursor: pointer;">信息录入</span>
        </div>
        <div style="display: flex;">
          <div @click="add2" class="add">新增</div>
          <div @click="project = 1" class="back">返回</div>
        </div>
      </div>
      <div class="table">
        <div class="tr bg">
          <div class="td1">
            <p>序号</p>
          </div>
          <div class="td2 td">
            <p>设备属性名称</p>
          </div>
          <div class="td3s td" style="justify-content: center;">
            <p style="margin-right: 2rem;">操作</p>
          </div>
        </div>
        <div class="tr" v-for="(item, index) in tableData2" :class="index % 2 == 0?'bg1':'bg2'">
          <div class="td1">
            <p>{{ index+1 }}</p>
          </div>
          <div class="td2 td">
            <p>{{item.parameter}}</p>
          </div>
          <div class="td3s td" style="display: flex;padding-left: 0;">
            <div @click="edit4(item)" class="tdd" style="color: rgba(43, 201, 222, 1);">修改</div>
            <div @click="delete2(item)" class="tdd" style="margin-right: 0;color: rgba(250, 105, 105, 1);">删除</div>
          </div>

        </div>
      </div>
      <!-- <div class="pagination">
        <el-pagination @size-change="sizeChange1" @current-change="currentChange1" style="color: white" background
          :page-sizes="[10, 15, 20]" :page-size="size1" layout="total, sizes, prev, pager, next, jumper" :total="total1">
        </el-pagination>
      </div> -->

    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        project: 1,
        tableData: [],
        nyhj: 0,
        page1: 1,
        size1: 10,
        total1: 0,
        show1: false,
        input1: '',
        manufactor: '',
        specifications: '',
        sbId1: 0,
        show3: false,
        form3: {
          name: '',
          manufactor: '',
          specifications: '',
          type: 0
        },
        parameterList: [{
          name: ''
        }],
        // 2222222
        tableData2: [],
        page2: 1,
        size2: 10,
        total2: 0,
        show2: false,
        input2: '',
        sbId2: 0,
        show4: false,
        edit4_show: false,
        edit4_input: '',
        sbId4: 0,
        hjny_arr: ['环境设备', '能源设备'],
        hjny_current: 0,
      }
    },
    mounted() {
      this.getEquipment()
    },
    watch: {
      nyhj(val) {
        this.page1 = 1
        this.size1 = 10
        this.getEquipment()
      }
    },
    methods: {
      // 分页查询设备类型列表
      getEquipment() {
        var obj = {
          "page": this.page1,
          "size": this.size1,
          "type": this.nyhj
        }
        this.$newPost('/equipment/type/page', obj).then(res => {
          console.log(res, '设备类型')
          this.tableData = res.data.records
          this.total1 = res.data.total
        })
      },
      sizeChange1(e) {
        this.size1 = e
        this.getEquipment()
      },
      currentChange1(e) {
        this.page1 = e
        this.getEquipment()
      },
      edit1(item) {
        console.log(item)
        this.input1 = item.name
        this.sbId1 = item.id
        this.show1 = true
        this.manufactor = item.manufactor
        this.specifications = item.specifications
      },
      delete1(item) {
        console.log(item)
        this.$confirm('确认删除该设备类型?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$newDelete('/equipment/type/delete/' + item.id).then(res => {
            if (res.code == 2000) {
              this.$message({
                message: res.message,
                type: 'success'
              });
              this.getEquipment()
            } else {
              this.$message({
                message: res.message,
                type: 'warning'
              });
            }
          })
        })
      },
      confirm1() {
        if (this.input1 == '') {
          this.$message({
            message: '名称不能为空',
            type: 'warning'
          });
        }else if (this.manufactor == '') {
          this.$message({
            message: '生产厂家不能为空',
            type: 'warning'
          });
        }else if (this.specifications == '') {
          this.$message({
            message: '规格型号不能为空',
            type: 'warning'
          });
        } else {
          var obj = {
            "id": this.sbId1,
            "name": this.input1,
            "manufactor":this.manufactor,
            "specifications": this.specifications
          }
          this.$newPost('/equipment/type/update', obj).then(res => {
            if (res.code == 2000) {
              this.$message({
                message: res.message,
                type: 'success'
              });
              this.show1 = false
              this.getEquipment()
            } else {
              this.$message({
                message: res.message,
                type: 'warning'
              });
            }
          })
        }
      },
      getData2() {
        var obj = {
          "page": 1,
          "size": 999,
          "equipmentTypeId": this.sbId1
        }
        this.$newPost('/equipment/type/parameter/page', obj).then(res => {
          console.log(res, '设备类型详情')
          this.tableData2 = res.data.records
          this.total2 = res.data.total
        })
      },
      handleTb1(item) {
        console.log(item)
        this.project = 2
        this.sbId1 = item.id
        this.getData2()
      },
      add2() {
        this.show2 = true
      },
      confirm2() {
        if (this.input2 == '') {
          this.$message({
            message: '不能为空',
            type: 'warning'
          });
        } else {
          var obj = {
            "equipmentTypeId": this.sbId1,
            "parameter": this.input2
          }
          this.$newPost('/equipment/type/parameter/save', obj).then(res => {
            if (res.code == 2000) {
              this.$message({
                message: res.message,
                type: 'success'
              });
              this.show2 = false
              this.getData2()
              this.input2 = ''
            } else {
              this.$message({
                message: res.message,
                type: 'warning'
              });
            }
          })
        }
      },
      delete2(item) {
        console.log(item)
        this.$confirm('确认删除该设备类型?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$newDelete('/equipment/type/parameter/delete/' + item.id).then(res => {
            if (res.code == 2000) {
              this.$message({
                message: res.message,
                type: 'success'
              });
              this.getData2()
            } else {
              this.$message({
                message: res.message,
                type: 'warning'
              });
            }
          })
        })
      },
      edit4(item) {
        // console.log(item)
        this.edit4_show = true
        this.edit4_input = item.parameter
        this.sbId4 = item.id
      },
      delete4(item) {

      },
      confirm4() {
        if (this.edit4_input == '') {
          this.$message({
            message: '不能为空',
            type: 'warning'
          });
        } else {
          var obj = {
            id: this.sbId4,
            parameter: this.edit4_input
          }
          this.$newPost('/equipment/type/parameter/update', obj).then(res => {
            if (res.code == 2000) {
              this.$message({
                message: res.message,
                type: 'success'
              });
              this.edit4_show = false
              this.getData2()
            } else {
              this.$message({
                message: res.message,
                type: 'warning'
              });
            }
          })
        }
      },
      add3() {
        this.parameterList.push({
          name: ''
        })
      },
      del3(index) {
        this.parameterList.splice(index, 1)
      },
      confirm3() {
        var arr = []
        for (var i = 0; i < this.parameterList.length; i++) {
          if (this.parameterList[i].name != '') {
            arr.push(this.parameterList[i].name)
          }
        }
        // this.parameterList.forEach((item, index) => {
        //   if(item.name !='') {
        //     arr.push(item.name)
        //   }
        // })
        var obj = {
          name: this.form3.name,
          manufactor: this.form3.manufactor,
          specifications: this.form3.specifications,
          type: this.form3.type,
          parameterList: arr
        }
        this.$newPost('/equipment/type/save', obj).then(res => {
          if (res.code == 2000) {
            this.$message({
              message: res.message,
              type: 'success'
            });
            this.show3 = false
            this.form3.name = ''
            this.manufactor = ''
            this.specifications = ''
            this.form3.type = 0
            this.form3.parameterList = []
            this.parameterList = [{
              name: ''
            }]
            this.getEquipment()
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .bg {
    background-color: rgba(43, 201, 222, 0.6);
  }

  .bg1 {
    background-color: rgba(162, 162, 162, 0.1);
  }

  .bg2 {
    background: rgba(14, 242, 242, 0.25);
  }

  .containerx {
    color: #ffffff;
    overflow: auto;
    background-color: #192534;
    padding: 1rem 0;
  }

  .title {
    font-size: 1.8rem;
    font-weight: 500;
    margin-left: 2rem;
    //margin-top: 2rem;
    height: 5rem;
    line-height: 5rem;
  }

  .add {
    cursor: pointer;
    background: #2BC9DE;
    border-radius: 0.5rem;
    width: 11.5rem;
    height: 3.8rem;
    line-height: 3.8rem;
    text-align: center;
    font-size: 1.4rem;
    color: #FFFFFF;
    margin-right: 1rem;
  }

  .back {
    cursor: pointer;
    border: 1px solid #1CB8B8;
    border-radius: 0.5rem;
    width: 6.7rem;
    height: 3.8rem;
    line-height: 3.8rem;
    text-align: center;
    color: rgba(28, 184, 184, 1);
    font-size: 1.4rem;
    margin-right: 2rem;
  }

  .table {
    width: 154.6rem;
    height: 60rem;
    overflow: auto;
    margin: 0 auto;

    .tr {
      display: flex;
      // margin-top: 1rem;
      padding: .5rem 0;

      .td1 {
        font-size: 1.4rem;
        width: 6.6rem;
        height: 4.2rem;
        margin-right: 0.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .td {
        font-size: 1.4rem;
        height: 4.2rem;
        display: flex;
        align-items: center;
        padding-left: 2rem;
        margin-right: 0.4rem;
      }

      .td2 {
        width: 103.4rem;
      }

      .eqNameTd {
        width: 41.7rem;
        justify-content:center;
        white-space: nowrap;
        text-overflow: ellipsis;

      }

      .specificationsTd {
        width: 30rem;
        justify-content:center;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .eqManufactorTd {
        width: 31.7rem;
        justify-content:center;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .td3s {
        width: 45.8rem;
      }

      .td3 {
        width: 22.7rem;
      }

      .tdd {
        // border: 1px solid #2BDEC9;
        width: 22.7rem;
        height: 4.2rem;
        margin-right: 0.4rem;
        text-align: center;
        line-height: 4.2rem;
        cursor: pointer;
      }
    }

    .tr:hover {
      background-color: rgba(117, 117, 117, 0.4);
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
  }

  .btngrp {
    margin-left: 1rem;
    margin-top: 1rem;
    display: flex;
    align-items: center;

    .btn1 {
      width: 10rem;
      height: 6.4rem;
      line-height: 6.4rem;
      text-align: center;
      color: rgba(43, 201, 222, 1);
      border-bottom: 1px solid rgba(43, 201, 222, 1);
      font-size: 1.8rem;
      margin-right: 3rem;
      cursor: pointer;
    }

    .btn2 {
      width: 10rem;
      height: 6.4rem;
      line-height: 6.4rem;
      text-align: center;
      color: rgba(43, 222, 201, 0.4);
      font-size: 1.8rem;
      margin-right: 3rem;
      cursor: pointer;
    }
  }
</style>
